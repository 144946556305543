/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-indigo/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";
@import "primeflex/primeflex.css";

body {
    margin: 0;
    padding: 0;
}

.req {
    color: red;
}

p-dropdown[ng-reflect-readonly="true"] {
    svg {
        display: none;
    }
}

input[readOnly], textarea[readOnly] {
    cursor: pointer;
}

p-dropdown.ng-invalid  {
    border-color: #e24c4c;
}